// @flow
import type { Styles } from './types';
import { hasNodeImageValue } from '~plugins/prismic/components/NodeImage';

const getBackgroundColor = theme => overlayColor =>
  ({
    Primary: `linear-gradient(90deg, ${theme.palette.primary.light} 0%, rgba(255,255,255,0) 100%)`,
    White: `linear-gradient(90deg, ${theme.palette.common.white} 0%, rgba(255,255,255,0) 100%)`,
    Black: `linear-gradient(90deg, ${theme.palette.common.black} 0%, rgba(255,255,255,0) 100%)`,
  }[overlayColor || 'Primary']);

const getBackgroundImageColor = theme => overlayColor =>
  ({
    Primary: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, rgba(210,15,58,0.1) 100%)`,
    White: `linear-gradient(90deg, ${theme.palette.common.white} 0%, rgba(255,255,255,0) 100%)`,
    Black: `linear-gradient(90deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)`,
  }[overlayColor || 'Primary']);

const styles: Styles = theme => ({
  root: ({ overlayColor, backgroundImage }) => ({
    ...(hasNodeImageValue(backgroundImage) && overlayColor
      ? { backgroundSize: 'cover' }
      : { background: getBackgroundColor(theme)(overlayColor) }),
    position: 'relative',
    minHeight: '55vh',
    height: '100%',
  }),
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: ({ overlayColor, backgroundImage }) =>
      hasNodeImageValue(backgroundImage) && overlayColor
        ? getBackgroundImageColor(theme)(overlayColor)
        : undefined,
    pointerEvents: 'none',
  },
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  textWrapper: {
    zIndex: 1,
  },
  title: {
    color: ({ overlayColor, backgroundImage }) =>
      hasNodeImageValue(backgroundImage) && overlayColor !== 'White'
        ? theme.palette.common.white
        : theme.palette.common.black,
    [theme.breakpoints.up('md')]: {
      maxWidth: '65%',
    },
  },
  subtitle: {
    '* + &': {
      marginTop: theme.spacing(3),
      color: ({ overlayColor, backgroundImage }) =>
        hasNodeImageValue(backgroundImage) && overlayColor !== 'White'
          ? theme.palette.common.white
          : 'inherit',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '55%',
    },
  },
});

export default styles;
