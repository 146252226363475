// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Container from '@material-ui/core/Container';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import classnames from 'classnames';
import compact from 'lodash/compact';
import map from 'lodash/map';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Fade from 'react-reveal/Fade';

import ResPadding from '~plugins/material-ui/components/ResPadding';
import RichText from '~plugins/prismic/components/RichText';
import NodeImage from '~plugins/prismic/components/NodeImage';
import StyledNotchedButton from '~components/StyledNotchedButton';
import FieldLink from '~plugins/prismic/components/FieldLink';
import VideoPlayer from '~components/VideoPlayer';

import type { Props } from './types';
import styles from './styles';

const CarouselHeader = ({
  items,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const theme = useTheme<Theme>();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(items, (item, index: number) => {
          const itemColorOverlay = item?.overlay_color;
          return item ? (
            <Slide
              key={index}
              index={index}
              aria-label={`slide-${index}`}
              className={classes.carouselItem}
            >
              {// eslint-disable-next-line no-nested-ternary
              item?.item_background_video?.url !== '' ? (
                <VideoPlayer
                  url={
                    isSmDown && item?.item_small_background_video?.url
                      ? item?.item_small_background_video?.url
                      : item?.item_background_video?.url
                  }
                  muted
                  playing
                  playsinline
                  loop
                  width="unset"
                  height="unset"
                  classes={{
                    root: classnames(
                      classes.backgroundVideo,
                      itemColorOverlay
                        ? {
                            Primary: classes.backgroundVideoColorPrimary,
                            White: classes.backgroundVideoColorWhite,
                            Black: classes.backgroundVideoColorBlack,
                          }[itemColorOverlay]
                        : undefined,
                    ),
                  }}
                />
              ) : item?.item_background_image ? (
                <>
                  <div
                    className={classnames(
                      classes.itemOverlay,
                      itemColorOverlay
                        ? {
                            Primary: classes.colorPrimary,
                            White: classes.colorWhite,
                            Black: classes.colorBlack,
                          }[itemColorOverlay]
                        : undefined,
                    )}
                  />
                  <NodeImage
                    data={item?.item_background_image}
                    className={classes.itemBackgroundImage}
                  />
                </>
              ) : null}

              <Container
                component={ResPadding}
                vertical
                className={classes.itemWrapper}
              >
                <div>
                  <Fade bottom cascade>
                    <RichText
                      {...item?.item_title}
                      className={classes.itemTitle}
                    />
                    <RichText
                      {...item?.item_subtitle}
                      className={classes.itemSubtitle}
                    />
                    {item.item_link &&
                    item.item_link_label &&
                    item.item_link_label?.text ? (
                      <div className={classes.itemButtonWrapper}>
                        <StyledNotchedButton
                          component={FieldLink}
                          field={item?.item_link}
                          variant="contained"
                          color={
                            item.overlay_color === 'Primary'
                              ? 'secondary'
                              : 'primary'
                          }
                          aria-label={item.item_link_label?.text}
                          className={classes.itemButton}
                        >
                          {item.item_link_label?.text}
                        </StyledNotchedButton>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Fade>
                </div>
              </Container>
            </Slide>
          ) : null;
        }),
      ),
    [items, isSmDown, classes],
  );

  return (
    <div className={classnames(classes.root, className)} {...props}>
      <CarouselProvider
        totalSlides={renderedItems.length}
        naturalSlideHeight={0}
        naturalSlideWidth={0}
        className={classes.carousel}
      >
        <Slider aria-label="slider">
          {renderedItems}
        </Slider>
        <DotGroup className={classes.paginationDots} />
      </CarouselProvider>
    </div>
  );
};

CarouselHeader.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'CarouselHeader' })(CarouselHeader);
