// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import classnames from 'classnames';

import SimpleHeader from '~components/SimpleHeader';
import ComplexHeader from '~components/ComplexHeader';
import CarouselHeader from '~components/CarouselHeader';

import type { Props } from './types';
import styles from './styles';

const getComponent = variant =>
  ({
    simple: SimpleHeader,
    complex: ComplexHeader,
    carousel: CarouselHeader,
  }[variant]);

const Header = ({
  componentVariant,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = getComponent(componentVariant);
  return (
    <Component className={classnames(classes.root, className)} {...props} />
  );
};

Header.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'Header' })(Header);
