// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import classnames from 'classnames';
import Fade from 'react-reveal/Fade';
import BackgroundImage from 'gatsby-background-image';

import ResPadding from '~plugins/material-ui/components/ResPadding';
import RichText from '~plugins/prismic/components/RichText';
import NodeImage, {
  hasNodeImageValue,
} from '~plugins/prismic/components/NodeImage';

import type { Props } from './types';
import styles from './styles';

const SimpleHeader = ({
  title,
  subtitle,
  backgroundImage,
  overlayColor,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const children = (
    <Container component={ResPadding} vertical className={classes.wrapper}>
      <Fade bottom cascade distance="40%">
        <div className={classes.textWrapper}>
          <RichText {...title} className={classes.title} />
          <RichText {...subtitle} className={classes.subtitle} />
        </div>
      </Fade>
    </Container>
  );

  return hasNodeImageValue(backgroundImage) ? (
    <NodeImage
      ImageComponent={BackgroundImage}
      data={backgroundImage}
      className={classnames(classes.root, className)}
      {...props}
    >
      <div className={classes.overlay} />
      {children}
    </NodeImage>
  ) : (
    <div className={classnames(classes.root, className)}>{children}</div>
  );
};

SimpleHeader.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'SimpleHeader' })(SimpleHeader);
