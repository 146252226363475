// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  carousel: {
    position: 'relative',
  },
  carouselItem: {
    height: '95vh',
    position: 'relative',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      height: '90vh',
    },
  },
  itemWrapper: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    textAlign: 'left',
    color: theme.palette.common.white,
    textShadow: '1px 1px 3px rgba(0,0,0,0.35)',
    zIndex: 1,
    '& > *': {
      [theme.breakpoints.up('md')]: {
        maxWidth: '80%',
      },
    },
  },
  itemTitle: {
    [theme.breakpoints.down('xs')]: {
      '& > *': {
        fontSize: '1.875rem',
      },
    },
  },
  itemSubtitle: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      '& > *': {
        fontSize: '1.125rem',
      },
    },
  },
  backgroundVideo: {
    position: 'absolute',
    alignItems: 'stretch',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    '& video': {
      objectFit: 'cover',
    },
    '&::before': {
      content: JSON.stringify(''),
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      opacity: 0.35,
      pointerEvents: 'none',
      [`@supports (mix-blend-mode: multiply)`]: {
        opacity: 1,
        mixBlendMode: 'multiply',
      },
    },
  },
  backgroundVideoColorPrimary: {
    '&::before': {
      background: theme.palette.primary.main,
    },
  },
  backgroundVideoColorWhite: {
    '&::before': {
      background: theme.palette.common.white,
    },
  },
  backgroundVideoColorBlack: {
    '&::before': {
      background: 'rgba(0,0,0,0.35)',
    },
  },
  itemButtonWrapper: {
    paddingTop: theme.spacing(6),
  },
  itemButton: {},
  itemOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: 0.35,
    pointerEvents: 'none',
    [`@supports (mix-blend-mode: multiply)`]: {
      opacity: 1,
      mixBlendMode: 'multiply',
    },
  },
  itemBackgroundImage: {
    width: '100%',
    height: '100%',
    position: 'absolute !important',
    top: 0,
    left: 0,
    zIndex: -1,
  },
  paginationDots: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    '& .carousel__dot': {
      width: 18,
      height: 18,
      borderRadius: '50%',
      padding: 0,
      borderStyle: 'solid',
      borderColor: theme.palette.common.white,
      backgroundColor: 'rgba(255,255,255,0.2)',
      margin: theme.spacing(1),
      '&:disabled': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  colorPrimary: {
    background: theme.palette.primary.main,
  },
  colorWhite: {
    background: theme.palette.common.white,
  },
  colorBlack: {
    background: 'rgba(0,0,0,0.35)',
  },
});

export default styles;
